import '@sass/common/theme.scss';
import CwLoadModules from '@customjs/module-loader.js';
import CwThemeFramework from '@customjs/generalFns.js';
import { MatchHeight } from 'js-match-height';

/**
 * Running mixed functions across the theme
 */
CwThemeFramework.generalFns();

/**
 * Bootstrap Tabs
 */
const tabs = document.querySelector('[role="tablist"]');

if (tabs) CwLoadModules.Tabs();

/**
 * Dropdown
 */
const dropdown = document.querySelector('.dropdown-toggle');

if (dropdown) CwLoadModules.Dropdown();

/**
 * Accordions
 */
const accordion = document.querySelector('.accordion');

if (accordion) CwLoadModules.Collapse();

/**
 * Service Worker
 */

// check if service worker is available

if ('serviceWorkerNO' in navigator) {
    document.addEventListener('DOMContentLoaded', () => {
        // Registering Service Worker
        navigator.serviceWorker
            // eslint-disable-next-line no-undef
            .register(`${ThemeURLS.childThemeURL}/sw.php`, {
                scope: '/',
            })
            .then((reg) => console.log(reg.scope))
            .catch((error) => console.log(error.message));
    });
}

/**
 * Loading Mobile Menu
 */

function matchMobile() {
    const mobileMediaQuery = window.matchMedia('(max-width: 768px)');
    return mobileMediaQuery.matches;
}

matchMobile() ? CwThemeFramework.mobileMenu() : '';

window.addEventListener('resize', () => {
    matchMobile() ? CwThemeFramework.mobileMenu() : '';
});

/**
 * Loading Modals in the Page
 * We will be pulling all the buttons with the data-cw-target
 * Attribute in order to initialize all the modals.
 */

// Checking for all out buttons
const modals = document.querySelectorAll('[data-cw-target]');
if (modals.length) {
    const modalInstances = [];
    CwLoadModules.modals().then((CwModals) => {
        modals.forEach((modalBtn, index) => {
            modalInstances[index] = new CwModals('.cw-modal');
            modalInstances[index].init();
        });
    });
}

/**
 * Loading Swiper JS
 * https://swiperjs.com/
 */
const carouselModules = {
    'results-slider': 'results-carousel',
    'results-slider__sidebar': 'results-slider-sidebar',
    'awards-wrap': 'awards-carousel',
    'awards-wrap-sidebar': 'awards-carousel-sidebar',
    'testimonials-section-slider': 'testimonials-carousel',
	'testimonials-section-slider-sidebar': 'testimonials-carousel-sidebar',
    'meet_our_team': 'team-carousel',
    'videos_section_slider': 'videos-carousel',
    'our-team': 'team-sidebar-widget',
    'organization_slider': 'organization-carousel',
    'awca_slider': 'awca-carousel',
};

// eslint-disable-next-line no-restricted-syntax
for (const [key, value] of Object.entries(carouselModules)) {
    const name = document.getElementById(key);
    if (name) {
        CwLoadModules.swiperSliders(value).then((module) => {
            module.default();
        });
    }
}

/**
 * Loading MatchHeight
 * Create new instance of MatchHeight [new MatchHeight()]
 * for elements that need to have the same height
 * https://www.npmjs.com/package/js-match-height
 */

const testimonialItem = new MatchHeight('.testimonials-section__item');
const whychooseusItem = new MatchHeight('.why-choose-us__item');

/**
 * Smooth Scroll
 * add class '.scroll' to new elements
 * class '.scroll' already added to footer back to top and TOC dynamic link elements
 */
// smooth scroll
document.addEventListener(
    'click',
    (event) => {
        if (!event.target.closest('.scroll')) return;

        const hashValue = event.target.closest('.scroll').getAttribute('href');
        const target = document.querySelector(hashValue);

        target.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
        history.pushState(null, null, hashValue);
        event.preventDefault();
    },
    false
);

/**
 * Load More
 */
const loadMore = null || document.querySelector('.load-more');
const categoryFilter = null || document.querySelector('.cw-filter');

if (loadMore || categoryFilter) {
    const enablePagination = loadMore.dataset.pagination;

    CwLoadModules.AjaxCalls().then((cwAjaxLib) => {
        const ajaxCalls = new cwAjaxLib({
            enablePagination: enablePagination,
        });
        ajaxCalls.init();
        if (categoryFilter) ajaxCalls.filter(categoryFilter);
    });
}

/**
 * Sticky Nav
 */
const stickyNav = () => {

    let scrollPos = window.scrollY;
    const stickyNav = document.querySelector('.top-header-mobile');
    
    const add_sticky = () => {
        stickyNav.classList.add('sticky-top');
    }
    
    const remove_sticky = () => {
        stickyNav.classList.remove('sticky-top');
    }
    
    window.addEventListener('scroll', (event) => {
        scrollPos = window.scrollY;
        
        if( scrollPos > 10 ) {
            if (window.matchMedia('(max-width: 991px)').matches) {
                add_sticky();
            }
        }
        else {
            remove_sticky();
        }
    });

};

stickyNav();

/**
 * Check for empty paginations
 */
const pagination = document.querySelector('.blog-pagination');
if( pagination ) {
    if( pagination.children.length < 1 ) { 
        pagination.remove(); //Removes cws_pagination if pagination is empty.
    }
}