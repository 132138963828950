import CwLoadModules from '@customjs/module-loader.js';

const CwThemeFramework = {
    async generalFns() {
        const office = document.querySelector('.select-office');
        if (office) {
            this.loadLocations(office);
            office.addEventListener('change', () => this.loadLocations(office));
        }

    },

    /**
     * Load Locations
     */
    async loadLocations(locationID) {
        if (locationID) {
            CwLoadModules.modals().then((modals) => {
                const officeHours = new modals('.cw-modal');
                officeHours.init();
            });
            const apiURL = `/api/location/${locationID.value}/`;
            const officeBox = document.querySelector('.office-info');
            const officeHours = document.querySelector('.opening-hours');
            const officeInfo = await fetch(apiURL);
            const officeDetails = await officeInfo.json();

            if (officeDetails.success) {
                const {
                    firmName,
                    directionURL,
                    city,
                    state,
                    zip,
                    location_address_1,
                    location_address_2,
                    phone,
                    email,
                    toll_free_number,
                    location_fax,
                    footer_map,
                    monday_hours,
                    tuesday_hours,
                    wednesday_hours,
                    thursday_hours,
                    friday_hours,
                    saturday_hours,
                    sunday_hours,
                } = officeDetails.data;

                const firmNameHTML = firmName ? `<strong>${firmName}</strong>` : '';
                const address1HTML = location_address_1 ?
                    `<span>${location_address_1}</span>` :
                    '';
                const address2HTML = location_address_2 ?
                    `<span>${location_address_2}</span>` :
                    '';
                const phoneHTML = phone ?
                    `<a href="tel:${phone}" class="ctm-no-swap" aria-label="Phone">${phone}</a>` :
                    '';
                const emailHTML = email ?
                    `<a href="mailto:${email}" aria-label="Email Address">${email}</a>` :
                    '';
                const officeInfoButtons = `<div class="office-info__buttons">
                   <a href="${directionURL}" target="_blank" aria-label="Direction Link">Direction</a>
                   <button class="btn cw-modal" data-cw-target="#hoursModal" id="office-hours">Office Hours</button>
               </div>`;

                const footerMapHTML = footer_map ?
                    `<figure><a href="${directionURL}" target="_blank" aria-label="Direction Link"><img src="${footer_map}" alt="${firmName}" /></a></figure>` :
                    '';

                const address = `${firmNameHTML}
               <address>
                   ${address1HTML}<br>
                   ${address2HTML}<br>
                   <span>${city}, ${state} ${zip}</span><br>
               </address>
               ${phoneHTML}<br>
               ${emailHTML}${officeInfoButtons}
               ${footerMapHTML}`;
                officeBox.innerHTML = address;

                const mondayHoursHTML = monday_hours ?
                    `<tr><td class="day">Monday</td><td class="time"><span>${monday_hours}</span></td></tr>` :
                    '';
                const tuesdayHoursHTML = tuesday_hours ?
                    `<tr><td class="day">Tuesday</td><td class="time"><span>${tuesday_hours}</span></td></tr>` :
                    '';
                const wednesdayHoursHTML = wednesday_hours ?
                    `<tr><td class="day">Wednesday</td><td class="time"><span>${wednesday_hours}</span></td></tr>` :
                    '';
                const thursdayHoursHTML = thursday_hours ?
                    `<tr><td class="day">Thursday</td><td class="time"><span>${thursday_hours}</span></td></tr>` :
                    '';
                const fridayHoursHTML = friday_hours ?
                    `<tr><td class="day">Friday</td><td class="time"><span>${friday_hours}</span></td></tr>` :
                    '';
                const saturdayHoursHTML = saturday_hours ?
                    `<tr><td class="day">Saturday</td><td class="time"><span>${saturday_hours}</span></td></tr>` :
                    '';
                const sundayHoursHTML = sunday_hours ?
                    `<tr><td class="day">Sunday</td><td class="time"><span>${sunday_hours}</span></td></tr>` :
                    '';

                const hoursOffice = `<table>
                              <tbody>
                              ${mondayHoursHTML}
                              ${tuesdayHoursHTML}
                              ${wednesdayHoursHTML}
                              ${thursdayHoursHTML}
                              ${fridayHoursHTML}
                              ${saturdayHoursHTML}
                              ${sundayHoursHTML}
                              </tbody>
                            </table>`;

                // Hours Modal
                officeHours.innerHTML = hoursOffice;
            }
        }
    },

    /**
     * Mobile Menu
     */
    mobileMenu() {
        const mobileButton = document.querySelector('.mobile-button');
        const mobileMenuElement = document.querySelector('#mmenu');
        const mobileChecker = document.querySelector('.mm-ocd');

        if (mobileButton && mobileMenuElement) {
            // Call mmenujs module
            CwLoadModules.mmenu().then((MmenuLight) => {
                if (!mobileChecker) {
                    const menu = new MmenuLight(mobileMenuElement);
                    menu.navigation();
                    const drawer = menu.offcanvas();

                    // Register event for listening to clicks
                    mobileButton.addEventListener('click', () => drawer.open());

                    /**
                     * Close Button
                     */
                    const closeButton = document.querySelector('.close__mobileMenu');

                    closeButton.addEventListener('click', () => drawer.close());

                    /**
                     *  Appending a click option for link tags
                     * with a hash(#) symbol at the beginning of the href
                     * */

                    const aHashTag = mobileMenuElement.querySelectorAll('a[href="#"]');
                    aHashTag.forEach((tag) => {
                        tag.addEventListener('click', (event) => {
                            event.target.parentNode.click();
                            event.preventDefault();
                        });
                    });
                }
            });
        }
    },
};

export default CwThemeFramework;